


























































































































































































































































































































































































































































































































































import VueBase from '@/VueBase'
import { Component } from 'vue-property-decorator'

@Component({ name: 'Deploy' })
export default class Deploy extends VueBase {
  private activeName = 'Spring-boot/Netty/Jetty/Sofa'
  private language = 'java'
  private token = ''
  private defaultTemplate = ''
  private uri = window.location.origin + '/openapi'
  private documents = []
  private tagOptions = []
  private md = {}
  private agentForm: any = {
    entryName: '',
    department: '',
    version: '',
    projectTemplate: '',
  }
  private departmentList = []
  private projectList = []

  private obj = {
    java: {
      key: 'JAVA',
      name: 'Java',
      term: [
        this.$t('views.deploy.java.term1'),
        this.$t('views.deploy.java.term2'),
        this.$t('views.deploy.java.term3'),
        this.$t('views.deploy.java.term4'),
        this.$t('views.deploy.java.term5'),
        this.$t('views.deploy.java.term6'),
      ],
      download: 'java -jar dongtai-agent.jar -m install -p <pid>',
      video:
        'https://huoqi-public.oss-cn-beijing.aliyuncs.com/iast/install_java_agent.mp4',
    },
    python: {
      key: 'PYTHON',
      name: 'Python',
      term: [
        this.$t('views.deploy.python.os'),
        this.$t('views.deploy.python.term1'),
        this.$t('views.deploy.python.term2'),
        this.$t('views.deploy.python.term3'),
        this.$t('views.deploy.python.termA'),
        this.$t('views.deploy.python.termAa'),
        this.$t('views.deploy.python.termAb'),
        this.$t('views.deploy.python.termAc'),
        this.$t('views.deploy.python.termAd'),
        this.$t('views.deploy.python.term4'),
        this.$t('views.deploy.python.term4-1'),
        this.$t('views.deploy.python.term4-2'),
        this.$t('views.deploy.python.term6'),
        this.$t('views.deploy.python.term6-1'),
        this.$t('views.deploy.python.term6-2'),
        this.$t('views.deploy.python.term6-3'),
      ],
      download: 'pip3  install ./dongtai-agent-python.tar.gz',
      video:
        'https://huoqi-public.oss-cn-beijing.aliyuncs.com/iast/instatll_python_agent.mp4',
    },
    php: {
      key: 'PHP',
      name: 'PHP',
      term: [this.$t('views.deploy.php.term1')],
      download: 'pip3  install ./dongtai-agent-python.tar.gz',
      video:
        'https://huoqi-public.oss-cn-beijing.aliyuncs.com/iast/instatll_python_agent.mp4',
    },
    go: {
      key: 'GO',
      name: 'GO',
      term: [this.$t('views.deploy.go.term1')],
      download: '',
      video:
        'https://huoqi-public.oss-cn-beijing.aliyuncs.com/iast/instatll_python_agent.mp4',
    },
  }
  changeLanguage(language: string) {
    this.language = language
    if (language === 'java') {
      this.activeName = 'SpringBoot'
    }
    if (language === 'python') {
      this.activeName = 'Django'
    }
    if (language === 'php') {
      this.activeName = ''
      this.getDoc()
      return
    }
    if (language === 'go') {
      this.activeName = ''
      this.getDoc()
      return
    }
    this.getDoc()
    this.getMd()
  }

  get shell() {
    switch (this.language) {
      case 'java':
        return `curl -X GET "${this.uri}/api/v1/agent/download?url=${
          this.uri
        }&language=java&token=${
          this.agentForm.department || this.token
        }&projectName=${
          (this.agentForm.entryName && encodeURI(this.agentForm.entryName)) ||
          'Demo%20Project'
        }&projectVersion=${
          (this.agentForm.version && encodeURI(this.agentForm.version)) ||
          'V1.0'
        }&template_id=${
          this.agentForm.projectTemplate || this.defaultTemplate
        }" -H "Authorization: Token ${
          this.agentForm.department || this.token
        }" -o dongtai-agent.jar -k`

      case 'python':
        return `curl -X GET "${this.uri}/api/v1/agent/download?url=${
          this.uri
        }&language=python&token=${
          this.agentForm.department || this.token
        }&projectName=${
          (this.agentForm.entryName && encodeURI(this.agentForm.entryName)) ||
          'Demo%20Project'
        }&projectVersion=${
          (this.agentForm.version && encodeURI(this.agentForm.version)) ||
          'V1.0'
        }&template_id=${
          this.agentForm.projectTemplate || this.defaultTemplate
        }" -H "Authorization: Token ${
          this.agentForm.department || this.token
        }" -o dongtai-agent-python.tar.gz -k`
      case 'php':
        return `curl -X GET "${this.uri}/api/v1/agent/download?url=${
          this.uri
        }&language=php&token=${
          this.agentForm.department || this.token
        }&projectName=${
          (this.agentForm.entryName && encodeURI(this.agentForm.entryName)) ||
          'Demo%20Project'
        }&projectVersion=${
          (this.agentForm.version && encodeURI(this.agentForm.version)) ||
          'V1.0'
        }&template_id=${
          this.agentForm.projectTemplate || this.defaultTemplate
        }" -H "Authorization: Token ${
          this.agentForm.department || this.token
        }" -o php-agent.tar.gz`
      case 'go':
        return `curl -X GET "${this.uri}/api/v1/agent/download?url=${
          this.uri
        }&language=go&token=${
          this.agentForm.department || this.token
        }&projectName=${
          (this.agentForm.entryName && encodeURI(this.agentForm.entryName)) ||
          'Demo%20Project'
        }&projectVersion=${
          (this.agentForm.version && encodeURI(this.agentForm.version)) ||
          'V1.0'
        }&template_id=${
          this.agentForm.projectTemplate || this.defaultTemplate
        }" -H "Authorization: Token ${
          this.agentForm.department || this.token
        }" -o dongtai-go-agent-config.yaml`
    }
  }
  get pythonShell() {
    return
  }
  private goDoc(url: string) {
    window.open(url)
  }

  private tagclick() {
    this.agentForm.tagshow = true
    this.$nextTick(() => {
      ;(this.$refs.agentTagInput as any).focus()
    })
  }
  private agentTagInputBlur() {
    this.agentForm.tagshow = false
  }
  private agentTagChange() {
    if (!this.agentForm.tagList.includes(this.agentForm.tagOption)) {
      this.agentForm.tagList.push(this.agentForm.tagOption)
    }
    this.agentForm.tagOption = ''
  }
  private deletag(key: any) {
    this.agentForm.tagList = this.agentForm.tagList.filter((item: any) => {
      return item !== key
    })
  }
  private onCopy() {
    this.$message({
      message: '已复制',
      type: 'success',
    })
  }

  private onError() {
    this.$message({
      message: '复制失败！',
      type: 'error',
    })
  }

  private async getMd() {
    if (this.md[this.activeName]) {
      return
    }
    const res = await this.services.deploy.getMD({
      language: this.language,
      middleware: this.activeName,
    })
    if (res.status === 201) {
      this.$set(this.md, this.activeName, res.data.desc)
    } else {
      this.$message.error(res.msg)
    }
  }

  private async downloadAgent() {
    const url =
      window.location.origin +
      '/api/v1/agent/download?url=' +
      window.location.origin +
      '/openapi&language=' +
      this.language +
      `&token=${this.agentForm.department || this.token}&projectName=${
        (this.agentForm.entryName && encodeURI(this.agentForm.entryName)) ||
        'Demo%20Project'
      }&projectVersion=${
        (this.agentForm.version && encodeURI(this.agentForm.version)) || 'V1.0'
      }&template_id=${this.agentForm.projectTemplate || this.defaultTemplate}`
    console.log('url', url)
    window.open(url)
  }
  private async getDoc() {
    const docRes = await this.services.deploy.getDocuments({
      language: this.language,
    })
    if (docRes.status === 201) {
      this.documents = docRes.data.documents
    }
  }

  private async getUserToken() {
    const res = await this.services.user.userToken()
    if (res.status === 201) {
      this.token = res.data.token
    }
  }

  private async created() {
    this.agentForm.entryName = 'Demo Project'
    this.agentForm.version = 'V1.0'
    await this.getMd()
    await this.getDoc()
    this.getUserToken()
  }
}
